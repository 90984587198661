import get from 'lodash.get'

export default {
  methods: {
    inputStates(field) {
      if (get(this, `v$.${field}.$error`, false))
        return 'error'
      else
        return 'default'
    }
  }
}